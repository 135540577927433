import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../shared/components/Layouts/Inner';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import SEO from '../../shared/components/SEO';
import Hero from '../../shared/components/Hero/index';
import HeroImg from '../../shared/images/banners/placeholder.jpg';
import PressReviews from '../../shared/components/PressReviews';
import Process from '../../shared/components/Process';
import ServicesInfo from '../../shared/components/Services/ServicesInfo';
import Professionals from '../../shared/components/Testimonial/Professionals';
import Faq from '../../shared/components/Collapse/Faq';
import Testimonials from '../../shared/components/Testimonial/Testimonials';
import AppDownload from '../../shared/components/AppDownload';
import WhyChooseUs from '../../shared/components/WhyChooseUs';
import Block from '../../shared/components/Block';
import LocationsList from '../../shared/components/Locations/List';

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "deep-cleaning" }) {
      title
      slug
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const DeepCleaning = ({ data }) => (
  <Layout url="deep-cleaning" darkMode>
    <SEO title="Deep Cleaning" />
    <Hero image={HeroImg} heading="Deep Cleaning" showReviews>
      <p>
        Panicking about getting that kitchen degreased or wanting a spring clean but haven't the
        time? Don't stress our professional teams have you covered providing tailored cleaning
        solutions throughout the North East.
      </p>
    </Hero>
    <PressReviews />
    <WhyChooseUs heading="Deep Cleans">
      <Block>
        <div>
          <h3>Professional Experience</h3>
          <p>
            Deep Cleans can be quite challenging especially if you are under pressure for a property
            inspection, post renovation or have a family celebration coming up. Our cleaning
            professionals have experience and the know how so make these daunting tasks look
            incredibly easy saving you valuable time and money. Deep cleans are bookable by the hour
            and allow you to prioritize areas needing that extra attention. Perfect for tailored
            cleans such as bathroom blitzing, spring cleaning, degreasing your kitchen areas etc...
          </p>
        </div>
        <div>
          <h3>Highly Proficient</h3>
          <p>
            We believe in delivering a 100% guaranteed high end service. Our deep cleaning
            specialists have been professionally trained to provide tailored deep cleaning befitting
            our clients requests. We understand at Maidupnorth how important these one time cleans
            are for our clients. Knowing your home or office is in a sparkly clean condition for
            those special events is vital to allow our fabulous clients peace of mind.
          </p>
        </div>
      </Block>
      <br />
      <Block>
        <div>
          <h3>Reliablility</h3>
          <p>
            Our band of Mighty Maids use state of the art time and geo sensitive apps to ensure all
            maids are tracked from job to job. Our customer friendly team will inform you if your
            maid is running over 10 minutes behind schedule. We offer all our clients the
            opportunity to rate their cleaner on our transparent feedback system post clean. This
            feedback is key and helps assure your assigned maids quality will be no less than 4.5
            stars as this is the minimum requirement to be a Maidupnorth cleaner. Our maids are not
            only trained and vetted but quality and customer service is monitored constantly.
          </p>
        </div>
        <div>
          <h3>Flexibility</h3>
          <p>
            How many times have we all had a last minute call to say family or friends intend to
            visit within the next few days or work has been asked to host an impromptu important
            seminar? Don`t panic we have a band of maids specially selected for these last minute
            cleaning requests. We will fit you in wherever possible. We can even cater for weekend
            work too as we understand not all events are held on a working week.
          </p>
        </div>
      </Block>
    </WhyChooseUs>
    <Process />
    <ServicesInfo />
    <Professionals
      heading="Say hi to some of Deep Clean specialists"
      data={data.page.cleaningProfessionals}
    />
    <ContainerFixed>
      <Faq data={data.page.frequentlyAskedQuestions} />
    </ContainerFixed>

    <LocationsList />

    <ContainerFixed>
      <Testimonials />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default DeepCleaning;
